$font-family-heading: 'Nunito',
    sans-serif;
$font-family-base: 'Nunito',
    sans-serif;
// $font-family-heading: 'Open Sans', sans-serif;
// $font-family-base: 'Open Sans', sans-serif;
$font-size-base: 1rem;
// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;
// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;
// Custom color
$cus-color-primary: #0092b0;
$cus-color-secondry: #f4821f;
$cus-color-white: #ffffff;
$edit-color: #00c455;
$delete-color: #f13426;
$view-color: #ffb52b;
$black-color: #000000;
$ask-color: #444444;
$icon-mute-color: #999999;
$bg-light-ask: #f8f9fa;
$border-line: #ededed;
$error-message: #d22346;
$select-box-color: #f4f5fc;
// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);