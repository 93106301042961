// Helper class

.en--p-15 {
    padding: 15px;
}

.cus-color-primary {
    color: $cus-color-primary;
}

.cus-color-secondry {
    color: $cus-color-secondry;
}

.cus-white-color {
    color: $cus-color-white;
}

.bg-pre-98 {
    background-color: rgb(0 146 176 / 94%);
}

.btn-know-more {
    background-color: $cus-color-secondry;
    color: $cus-color-white;
    transition-delay: 0.3ms;

    &:hover {
        background-color: $cus-color-white;
        color: $cus-color-primary;
    }
}

.cus-black-color {
    color: $black-color;
}

.en-plr0-tb-15 {
    padding: 15px 0;
}

.en-block-100 {
    display: block;
    width: 100%;
    clear: both;
}

.row-small-gutter {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.row-small-gutter>* {
    padding-left: 7.5px;
    padding-right: 7.5px;
}