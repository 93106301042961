// Include session, Form, buttons
//@extend vertical center
.flex-vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

//@extend bacground-image
.wizard-indicator {
    position: absolute;
    content: '';
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
}

.en-wizard-container {
    background-image: url('../../../images/en-login.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.en-login-left {
    background-color: rgba(255, 255, 255, 0.9);
}

.auth-logo {
    img {
        width: 90px;
        height: 90px;
    }
}

.cus-login-container {


    @extend .en-wizard-container;

    .cos-session-form {
        @extend .flex-vertical-center;
        padding: 20px 100px;

        &.col-login {
            max-width: 620px;
        }
    }

    .form-group {
        .form-control {
            background: transparent;
            border: 1px solid #ced4da;
            border-radius: 5px;
            height: calc(3.5rem + 2px);
            padding: 0.375rem 0.9rem;
        }

        label {
            font-size: 14px;
            color: #212121;
            margin-bottom: 4px;
        }

        .error {
            color: $error-message;
            font-size: 13px;
        }
    }

    .en-company-user {
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;

        .form-check-input {
            margin-top: 9px;
        }

        .form-check {
            position: relative;
            border: 2px solid $select-box-color;
            background-color: $select-box-color;
            padding: 2px 4px;
            border-radius: 5px;

            p {
                padding-top: 10px;

                span {
                    position: absolute;
                    top: 0px;
                    left: 29px;
                    font-size: 10px;
                    font-weight: 400;
                    color: $cus-color-secondry;
                }
            }
        }
    }
}

.cus-create-password {
    .cus-create-pwd-form {
        @extend .flex-vertical-center;
    }
}

// Default form
.form-group {
    .form-control {
        background: #f4f5fc;
        border: none;
        height: calc(3rem + 2px);
        padding: 0.375rem 0.75rem;
    }

    label {
        font-size: 14px;
        color: #212121;
        margin-bottom: 4px;
    }
}

// Select 
.ng-select.ng-select-single {
    .ng-select-container {
        background: #f4f5fc;
        border: none;
        height: calc(3rem + 2px);
        padding: 0.375rem 0.75rem;

        &::before {
            content: '';
            position: absolute;
            // background-image: url('../../../images/down.svg');
            background-repeat: no-repeat;
            top: 18px;
            right: 10px;
            width: 18px;
            height: 18px;
            transition-delay: 0.4ms;
            opacity: 0.5;
        }
    }
}

// Buttons
.btn {
    padding: 0.6rem 2rem;
    border-radius: 25px;
    border: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Country code select
.en-country-code {
    .iti {
        width: 100%;

        &.separate-dial-code {
            input {
                width: 100%;
                background: #f4f5fc;
                border: none;
                height: calc(3.5rem + 2px);
                padding: 0.375rem 0.75rem;
            }
        }

        input#country-search-box {
            padding-left: 10px;
            height: calc(2.5rem + 5px);
        }

        .dropdown-menu {
            border: none;
            background-color: transparent;
            width: 430px;
        }
    }
}

//Custom radio button
input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: $cus-color-primary;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid $cus-color-primary;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-top: 5px;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $cus-color-primary;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid $cus-color-primary;
    outline-offset: max(2px, 0.15em);
}

.form-check-label {
    padding-left: 25px;
}

//Wizard
.cus-wizard-container {
    .card {
        @extend .flex-vertical-center;
        background-color: transparent;
        padding: 20px 100px;
        box-shadow: none;

        .card-block {
            width: 100%;
            padding: 15px;
            //overflow-x: hidden;
            overflow-y: initial;
            clear: both;
        }

        .card-header {
            display: block;
            width: 100%;
            border: none;
            padding: 0 15px;
        }

        .card-footer {
            display: block;
            width: 100%;
            text-align: right;
        }
    }

    form-wizard {
        .card {
            .nav {
                display: block;

                .nav-item {
                    float: left;
                    margin-right: 10px;

                    a {
                        position: relative;
                        border-bottom: none;
                        height: 25px;
                        display: inline-block;
                        padding-left: 30px;
                        font-weight: 600;
                        line-height: 25px;

                        &::before {
                            background-image: url(../../../images/check_mute.svg);
                            @extend .wizard-indicator;
                        }
                    }

                    &.enabled {
                        a {
                            color: #50ca5e;

                            &::before {
                                background-image: url(../../../images/check_enable.svg);
                                @extend .wizard-indicator;
                            }
                        }

                        &.active {
                            a {
                                background: transparent;
                                border-color: transparent;
                                color: #000000;

                                &::before {
                                    background-image: url(../../../images/check_active.svg);
                                    @extend .wizard-indicator;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                .btn {
                    color: #ffffff;
                    background-color: #0092b0;
                    background-image: none;
                    border-color: #0092b0;

                    &:disabled {
                        background: #e5f4f7;
                        color: #000000;
                    }
                }
            }
        }
    }

    .cus-connect-container {
        position: relative;
        background-color: #0092b0;
        padding: 20px;
        border-radius: 10px;
        min-height: 320px;

        .avatar {
            background-color: #ffffff;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            padding: 10px;
        }

        .cus-radio {
            position: absolute;
            top: 10px;
            right: 10px;

            input[type="radio"] {
                color: $cus-color-white;
                border: 0.15em solid $cus-color-white;
            }

            input[type="radio"]::before {
                box-shadow: inset 1em 1em $cus-color-white;
            }

            input[type="radio"]:focus {
                outline: max(2px, 0.15em) solid $cus-color-white;
            }
        }
    }

    .ng-dropdown-panel {
        background-color: #f4f5fc;
        position: absolute;
        padding: 10px 0px 10px 10px;
        border-radius: 4px;
        margin-top: 1px;

        .ng-dropdown-panel-items {
            max-height: 100px;

            .ng-option {
                padding-bottom: 6px;
                padding-top: 6px;
                border-bottom: 1px dashed #ededed;
            }
        }
    }

    //payment wizard
    .en-payment-control {
        form {
            position: relative;

            .pay-now-btn {
                position: absolute;
                right: 0;
                bottom: -70px;
            }
        }
    }
}

// common dropdown
.ng-dropdown-panel {
    background-color: #f4f5fc;
    position: absolute;
    padding: 10px 0px 10px 10px;
    border-radius: 4px;
    margin-top: 1px;

    .ng-dropdown-panel-items {
        max-height: 100px;

        .ng-option {
            padding-bottom: 6px;
            padding-top: 6px;
            border-bottom: 1px dashed #ededed;
        }
    }
}

// Non session wizard
.cus-wizard-container {
    &.non-session-wizard {
        .card {
            display: block;
            justify-content: inherit;
            align-items: inherit;
            flex-direction: inherit;
            min-height: auto;
            padding: 20px 15px;
        }
    }
}

.cus-payment-card {
    position: relative;
    background-image: url('../../../images/credit-card.png');
    background-repeat: no-repeat;
    width: 335px;
    height: 215px;
    border-radius: 10px;
    padding: 22px 12px;

    h5 {
        color: #ffffff;
    }

    label {
        color: #ffffff;
        font-size: 18px;
    }

    .cus-card-info {
        position: absolute;
        bottom: 50px;
        left: 10px;
    }
}

.cus-info-section {
    @extend .flex-vertical-center;
}

// Custom scrollbar
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(153, 153, 153);
}

// Media query 
@media only screen and (max-width: 1366px) {
    .cus-wizard-container {
        .card {
            padding: 20px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .cus-wizard-container {
        .card {
            padding: 20px 15px;
        }
    }

    .cus-login-container .cos-session-form {
        padding: 20px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .cus-wizard-container {
        .card {
            padding: 20px 0;
        }
    }
}