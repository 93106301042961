@media only screen and (max-width: 767px) {

    html,
    body {
        overflow-x: hidden;
        clear: both;
    }

    .en-sm-none {
        display: none;
    }

    .en-login-left {
        margin-top: 40px;
    }

    .en-lock-out {
        right: 30px;
        top: -25px;
    }
}