.InputContainer .InputElement:focus {
    color: #ffffff !important;
}

.layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
    background: #001114;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item {
    color: #ffffff;
    border-bottom: 1px solid #000000;
    border-top: 1px solid #002228;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:hover {
    background-color: #0092b0;
    transition-delay: 0.3ms;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active {
    color: #ffffff;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
    border-color: transparent transparent #0092b0 transparent;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:first-child {
    border-top: none;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item:last-child::before {
    content: '';
    border-bottom: 1px solid #002228;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: -2px;
}

.layout-sidebar-large .sidebar-left-secondary.open {
    background-color: $cus-color-white;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fbfbfb;
}

.modal-content {
    border: none;
}

.en-remove-span {
    position: inherit !important;
    background-color: transparent !important;
    color: #000000 !important;
    font-size: 15px !important;
    border-radius: none !important;
}

.btn.btn-outline-light.btn-svg.active,
.btn.btn-outline-light.btn-svg:hover {
    background: #0092b0;
    border-color: #0092b0;
    color: #ffffff;
}

// table 
.cus-table-container {
    .table {
        margin-bottom: 0;

        .thead-light {
            th {
                color: #ffffff;
                background-color: #0092b0;
                border: none;
            }
        }
    }

    .empty-item {
        padding: 60px 0;
    }
}
.empty-item-add {
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.en-upload-wrapper {
    .en-upload-img {
        position: relative;
        width: 80px;
        height: 80px;
        display: inline-block;
        border: 2px dashed #cccccc;
        margin-right: 5px;
        overflow: hidden;
        padding: 6px;
        margin-top: 20px;
        border-radius: 15px;

        img {
            width: 80px;
            height: auto;
            object-fit: cover;
        }

        .en-close-product {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .custom-file-input {
        height: calc(3.5rem + 2px);
    }

    .input-group-text {
        border: 1px solid #ced4da;
        background-color: #ffffff;
        border-radius: 5px;
        height: 50px;
        color: #a3a3a3;
    }

    .input-group>.custom-file:not(:first-child) .custom-file-label {
        padding: 15px;
        height: 50px;
    }

    .custom-file-label {
        top: 0;
        left: 0;
        border-radius: 5px;
    }

    .custom-file-label::after {
        height: 50px;
        padding: 15px;
        color: #f4f5fc;
        content: "Browse";
        background-color: transparent;
    }
}